import React, { useRef, useEffect, useContext } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { CampaignCard } from "../components/CampainCard";
import { HeaderMain } from "../components/HeaderSelector";
import { FullScreenScrollableCard } from "../components/FullScreenScrollableCard";
import { useNavigate } from "react-router-dom";
import { CampaignContext } from "../context/CampaignContexts";

export const ProjectsPage: React.FC = () => {
  const { campaigns } = useContext(CampaignContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "ArrowRight") {
      event.preventDefault();

      containerRef.current?.scrollBy({
        left: window.document.body.clientWidth || 0,
        behavior: "smooth",
      });
    } else if (event.key === "ArrowLeft") {
      event.preventDefault();

      containerRef.current?.scrollBy({
        left: -window.document.body.clientWidth || 0,
        behavior: "smooth",
      });
    }
  };

  // register keydown event listener on document
  useEffect(() => {
    const fn = (event: KeyboardEvent) => handleKeyDown(event);

    document.addEventListener("keydown", fn);
    return () => {
      document.removeEventListener("keydown", fn);
    };
  }, []);

  const handleClick = (index: number) => (id?: string) => {
    if (!id) return;

    // redirect to capmaign details page using react router
    navigate(`/campaign/${id}`);
  };

  if (!campaigns) return <div>Loading...</div>;

  return (
    <Flex direction="column" py={4} height="100vh" overflow="hidden">
      <HeaderMain />
      <Box
        flex="1"
        ref={containerRef}
        display="flex"
        overflowX="scroll"
        pb={4}
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {campaigns.map((campain, index) => (
          <FullScreenScrollableCard key={index}>
            {/* <Link to={'project'}> */}
            <CampaignCard
              key={campain.id}
              {...campain}
              handleClick={handleClick(index)}
            />
            {/* </Link> */}
          </FullScreenScrollableCard>
        ))}
      </Box>
    </Flex>
  );
};
