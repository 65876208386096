import { useContext } from "react";
import { CampaignContext } from '../context/CampaignContexts';

export const useCampaign = (id?: string) => {
    const { campaigns } = useContext(CampaignContext);

    console.log(campaigns, id);

    if (!campaigns || !id) {
        return null;
    }

    const campaign = campaigns.find((campaign) => campaign.id === id);

    if (!campaign) {
        return null;
    }

    return campaign;
};

    