import { Box, Heading, Link } from "@chakra-ui/react";

export const HeaderMain: React.FC = () => {
  return (
    <Box
      borderColor={"brand.border"}
      as="header"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p={4}
      mb={4}
      borderBottomWidth="1px"
    >
      <Heading as="h1" size="lg" color="white" letterSpacing={"tighter"}>
        <Link href="/">Jorney</Link>
      </Heading>
    </Box>
  );
};
