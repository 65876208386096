import { HStack, Image, Text } from "@chakra-ui/react";

type StatsItemProps = {
  icon: string;
  value: number;
};

export const StatsItem = ({ icon, value }: StatsItemProps) => {
  return (
    <HStack mr="3">
      <Image h={8} src={icon} />
      <Text>{value}</Text>
    </HStack>
  );
};
