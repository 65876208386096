import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { Quest } from "../data/campains";

type QuestModalProps = {
  isOpen: boolean;
  handleCloseModal: () => void;
  quest?: Quest;
  handleOkButtonClick: (quest: Quest) => void;
};

export const QuestModal: React.FC<QuestModalProps> = ({
  isOpen,
  quest,
  handleCloseModal,
  handleOkButtonClick,
}) => {
  if (!quest) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{quest.title}</ModalHeader>
        <ModalBody>
          <p>{quest.description}</p>
        </ModalBody>
        <ModalFooter display="flex" justifyContent="center" gap="4">
          <Button onClick={() => handleOkButtonClick(quest)}>OK</Button>
          <Button onClick={handleCloseModal}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
