import React from "react";
import { Box, Button, Heading } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom"; // assuming you're using React Router for routing
import { MainHeader } from "../components/MainHeader";
import { QuestsList } from "../components/QuestsList";
import { CampaignContext } from "../context/CampaignContexts";
import { QuestModal } from "../components/QuestModal";
import { Quest } from "../data/campains";
import { finishQuest } from "../api/api";
import { useCampaign } from '../hooks/useCampaign';

interface Params extends Record<string, string | undefined> {
  id: string;
}

const ProjectDetails: React.FC = () => {
  const { id } = useParams<Params>();
  const navigate = useNavigate();

  const [selectedQuest, setSelectedQuest] = React.useState<Quest | undefined>();

  const handleSelectQuest = (quest: Quest) => {
    setSelectedQuest(quest || undefined);
  };

  const campaign = useCampaign(id);

  const handleOkButtonClick = async (quest: Quest) => {
    if (!selectedQuest || !id) return;

    await finishQuest(id, quest.id);

    setSelectedQuest(undefined);
  };

  if (!campaign) return <div>Loading...</div>;

  return (
    <Box>
      <MainHeader campaign={campaign} />
      <Box p={4}>
        <Heading>Quests</Heading>

        <QuestsList
          quests={campaign.todos}
          handleSelectQuest={handleSelectQuest}
        />

        <QuestModal
          isOpen={Boolean(selectedQuest)}
          quest={selectedQuest}
          handleCloseModal={() => setSelectedQuest(undefined)}
          handleOkButtonClick={handleOkButtonClick}
        />

        <Button onClick={() => navigate(`/quest/${id}`)}>Add Quest</Button>
      </Box>
    </Box>
  );
};

export default ProjectDetails;
