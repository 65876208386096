import {
  Box,
  HStack,
  Grid,
  GridItem,
  Heading,
  Text,
  Image,
} from "@chakra-ui/react";
import React from "react";
import { Campaign } from "../data/campains";
import { StatIcon } from "./StatIcon";
import { StatsItem } from "./StatsItem";
import { PowerContext } from "../context/PowerContext";
import { PowerLogModal } from "./PowerLogModal";

type Props = {
  campaign: Campaign;
};

// a header with money left number
export const MainHeader: React.FC<Props> = ({ campaign: { title, data } }) => {
  const { power } = React.useContext(PowerContext);

  return (
    <Grid
      borderColor={"brand.border"}
      as="header"
      mb={4}
      borderBottomWidth="1px"
      templateColumns="min-content 1fr min-content"
    >
      <GridItem>
        <Box borderColor={"brand.border"} borderRightWidth="1px" p={4}>
          <Heading color={"white"}>{title}</Heading>
        </Box>
      </GridItem>
      <GridItem>
        <Box p={4} h="full" display="flex">
          <StatsItem icon={"/static/coin.png"} value={data.moneyLeft} />
          <StatsItem icon={"/static/target.png"} value={data.audience} />
        </Box>
      </GridItem>
      <GridItem>
        <Box
          p={4}
          h="full"
          display="flex"
          alignItems="center"
          borderColor={"brand.border"}
          borderLeftWidth={"1px"}
          cursor="pointer"
          // onClick={}
        >
          <StatIcon icon="lightning" />
          <Text ml="2" fontSize="xl">
            {power ? power.currentPower : "loading..."}
          </Text>
          <Text ml="2" fontSize="xl" color={"gray.700"}>
            {power ? `(${power.totalPower})` : ""}
          </Text>
        </Box>
      </GridItem>

    </Grid>
  );
};
