// chacka ui theme extension
import { extendTheme, Input, Button } from '@chakra-ui/react';

export const bit8Theme = extendTheme({
  colors: {
    brand: {
      border: "#000",
    },
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: "0",
      },
    },
    Box: {
      baseStyle: {
        borderColor: "brand.border",
      },
    },
    Input: {
      baseStyle: {
        borderColor: "brand.border",
      },
    }
  },
  fonts: {
    heading: "'Press Start 2P', sans-serif",
    body: "'Press Start 2P', sans-serif",
  },
  styles: {
    global: {
      body: {
        backgroundColor: "#8B5A2B",
        color: "#000000",
      },
    },
  },
});
