import { Box, Heading, Image, VStack } from "@chakra-ui/react";
import { Campaign as Campaign } from "../data/campains";

type CampaignCardProps = Campaign & {
  handleClick: (id?: string) => void;
};

// A card taking the full width of the screen and shows the title, details and a figure
export const CampaignCard: React.FC<CampaignCardProps> = ({
  title,
  details,
  description,
  image,
  id,
  handleClick,
}) => {
  return (
    <VStack
      w={"50%"}
      border="15px solid #000"
      onClick={() => handleClick(id)}
      p={4}
      boxShadow="lg"
      bg="white"
      cursor="pointer"
    >
      <Heading as="h2" size="md" mb={2} fontWeight="bold" fontSize="xl">
        {title}
      </Heading>
      <Box>{description}</Box>
      <Image mb={4} alignSelf={"center"} w="50%" src={image} alt={title} />
      <Box pt={5}>{details}</Box>
    </VStack>
  );
};
