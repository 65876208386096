import { Icon } from "@chakra-ui/react";
import { BsLightningFill } from "react-icons/bs";

type StatIconProps = {
  icon: "lightning" | "coin" | "target";
  size?: number;
  color?: string;
};

export const StatIcon = ({ icon, size = 8, color }: StatIconProps) => {
  switch (icon) {
    case "lightning":
      return (
        <Icon
          as={BsLightningFill}
          color={color ?? "yellow.400"}
          w={size}
          h={size}
        />
      );
    // case 'coin':
    //     return <BsDiamondFill />;
    // case 'target':
    //     return <BsPeopleFill />;
  }

  return null;
};
