import * as React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CampaignContext } from "./context/CampaignContexts";
import { ProjectsPage } from "./pages/Campaigns";
import ProjectDetails from "./pages/MainCampaignPage";
import { AddQuestPage } from './pages/AddQuestPage';

export const App = () => {
  const { campaigns } = React.useContext(CampaignContext);

  if (!campaigns) return <div>Loading...</div>;

  return (
    <Router>
      <Routes>
        <Route path="/" element={<ProjectsPage />} />
        <Route path="/campaign/:id" element={<ProjectDetails />} />
        <Route path="/quest/:campaign" element={<AddQuestPage />} />
      </Routes>
    </Router>
  );
};
