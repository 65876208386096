import { createContext, useState, useEffect, ReactNode } from "react";
import { Power } from "../data/power";
import { getPower } from "../api/api";

type PowerContextProps = {
  power: Power | null;
};

export const PowerContext = createContext<PowerContextProps>({ power: null });

export const PowerProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [power, setPower] = useState<Power | null>(null);

  useEffect(() => {
    const loadPower = async () => {
      const power = await getPower();
      setPower(power);
    };

    loadPower();
  }, []);

  return (
    <PowerContext.Provider value={{ power }}>{children}</PowerContext.Provider>
  );
};
