import { Box, Center } from "@chakra-ui/react";
import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

// Component accepting children
export const FullScreenScrollableCard: React.FC<Props> = ({ children }) => {
  return (
    <Center
      w={["100vw"]}
      h={["fill-available"]}
      alignSelf="stretch"
      flexShrink={0}
      p={4}
    >
      {children}
    </Center>
  );
};
