import { Box, Heading, ListIcon, Text } from "@chakra-ui/react";
import { Quest } from "../data/campains";
import { BsDiamondFill } from "react-icons/bs";
import { StatIcon } from "./StatIcon";

export const QuestCard: React.FC<Quest & { onClick: () => void }> = ({
  id,
  title,
  description,
  done,
  reward,
  onClick,
}) => {
  return (
    <Box
      display="flex"
      cursor={done ? "default" : "pointer"}
      color={done ? "gray.400" : "black"}
      _hover={done ? undefined : { color: "white" }}
      onClick={!done ? onClick : undefined}
    >
      <ListIcon as={BsDiamondFill} color="brand.500" />
      <Heading as="h3" size="md" mb={2} fontWeight="bold" fontSize="l">
        {title} (reward:{" "}
        <StatIcon
          size={4}
          icon="lightning"
          color={done ? "gray.400" : undefined}
        />
        {reward.power})
      </Heading>
    </Box>
  );
};
