import axios from "axios";
import { Quest } from "../data/campains";
import { Power } from "../data/power";

const API_URL = "/api";

export const getCampaigns = async () => {
  return (await axios.get(`${API_URL}/campaigns`)).data;
};

export const getPower = async (): Promise<Power> => {
  return (await axios.get(`${API_URL}/power`)).data;
};

export const finishQuest = async (campaignId: string, questId: string) => {
  return (await axios.post(`${API_URL}/quest/finish`, { campaignId, questId }))
    .data;
};

export const createQuest = async (campaignId: string, quest: Quest) => {
    return (await axios.post(`${API_URL}/quest/create`, { campaignId, quest }))
        .data;
    }