import React from "react";
import { Box, Button, FormControl, FormLabel, Input, Textarea, VStack, Image, Text, Heading } from '@chakra-ui/react';
import { useNavigate, useParams } from "react-router-dom";
import { MainHeader } from '../components/MainHeader';
import { useCampaign } from "../hooks/useCampaign";
import { createQuest } from '../api/api';
import { useContext } from 'react';
import { CampaignContext } from "../context/CampaignContexts";

interface Params extends Record<string, string | undefined> {
    id: string;
}

// This is the page that allows the user to add a new quest
// accepts the campaign id as a parameter
// uses chackra ui to create a form and submit and cancel buttons
// cancel button returns to the main campaign page
// submit button calls the api to add the quest to the campaign
// and returns to the main campaign page
export const AddQuestPage = () => {
    const { campaign: campaignId } = useParams<Params>();
    const navigate = useNavigate();
    const { loadCampaigns } = useContext(CampaignContext);
    const campaign = useCampaign(campaignId);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if(!campaignId) return;

        // @ts-ignore
        const data = new FormData(event.target);
        const formDataObj = Object.fromEntries(data.entries());
    
        await createQuest(campaignId, {
            id: (formDataObj.title as string).toLocaleLowerCase().replace(/ /g, '_'),
            title: formDataObj.title as string,
            description: formDataObj.description as string,
            reward: {
                power: Number(formDataObj.reward),
            },
            done: false,
        });

        await loadCampaigns();

        navigate(-1);
    }

    const handleCancel = () => {
        navigate(-1);
    }

    if (!campaign) return <div>Loading...</div>;

    return (
        <Box>
            <MainHeader campaign={campaign} />

            <VStack display='flex'>
                <Box borderWidth='1px' borderColor='black' display="flex" flexDirection="column" alignItems='center' textAlign='center' maxWidth='1200px'>
                    <Image mt='5' mb='5' h='400px' w='400px' src='/static/wizard.png' alt='wizard casting spells' />

                    <Text display='block' mb='4'>
                        Welcome to the Jorney game quest generation page! Here, you can create your own unique jorney quests to challenge yourslef.
                    </Text>

                    <Text display='block' mb='4'>
                        To get started, simply enter a title for your quest in the designated field. This could be something like "Retrieve the Golden Sword" or "Create a marketing campaign." The title should be attention-grabbing and give players a clear idea of what they need to do.
                    </Text>

                    <Text display='block' mb='4'>
                    Next, write a description of the quest in the provided text box. Be creative and use your imagination to create a story that will engage players and make them want to complete the quest. Include any relevant details such as the location, the enemy or enemies to defeat, and any obstacles that need to be overcome.
                    Finally, set a reward for completing the quest in the "power points" field. This could be anything from 0.5 points for an easier quest to 12 points for a more difficult one. Make sure the reward is proportional to the difficulty of the quest to keep players motivated. Each point is worth one hour of rest, so a quest worth 12 points would be equivalent to a full day of rest plaing computet games.
                    </Text>

                    <Text display='block' mb='4'>
                    And don't forget to check out the image of the 8-bit wizard casting a spell - it's sure to inspire you as you create your quest. So what are you waiting for? Start creating your own 8-bit game quests now and challenge your players to an adventure they won't forget!
                    </Text>
                </Box>
                
                <Box borderWidth='1px' borderColor='black' minWidth='1200px' p='8'>
                    <Heading as='h2' size='md' mb='6' fontWeight='bold' fontSize='xl' color='white'>
                        Add Quest
                    </Heading>

                    <form onSubmit={handleSubmit}>
                        <FormControl mb='4' id="questName">
                            <FormLabel color='whote'>Quest Name</FormLabel>
                            <Input borderRadius={0} type="text" name="title" />
                        </FormControl>
                        <FormControl mb='4' id="questDescription">
                            <FormLabel color='whote'>Quest Description</FormLabel>
                            <Textarea borderRadius={0} name="description" />
                        </FormControl>
                        <FormControl mb='4' id="questPoints">
                            <FormLabel color='whote'>Quest Points</FormLabel>
                            <Input borderRadius={0} name="reward" />
                        </FormControl>

                        <Box display='flex' gap='4'>
                            <Button type="submit">Submit</Button>
                            <Button onClick={handleCancel}>Cancel</Button>
                        </Box>
                    </form>
                </Box>
            </VStack>
        </Box>
    )
}

    