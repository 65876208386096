import { createContext, ReactNode, useState } from "react";
import { getCampaigns } from "../api/api";
import { Campaign } from "../data/campains";
import { useEffect } from "react";

export const CampaignContext = createContext<{ campaigns: Campaign[] | null, loadCampaigns: () => void }>({
  campaigns: null,
  loadCampaigns: () => { },
});

export const CampaignProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [campaigns, setCampaigns] = useState<Campaign[] | null>(null);

  const loadCampaigns = async () => {
    const items = await getCampaigns();

    setCampaigns(items);
  };

  useEffect(() => {
    loadCampaigns();
  }, []);

  // expose loadCampaigns function and upload it to the context
  return (
    <CampaignContext.Provider value={{ campaigns, loadCampaigns }}>
      {children}
    </CampaignContext.Provider>
  );
};
