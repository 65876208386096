import { Box, List, ListItem, ListIcon } from "@chakra-ui/react";
import { Quest } from "../data/campains";
import { QuestCard } from "./QuestCard";
import { BsDiamondFill } from "react-icons/bs";

type Props = {
  quests: Quest[];
  handleSelectQuest: (quest: Quest) => void;
};

export const QuestsList: React.FC<Props> = ({ quests, handleSelectQuest }) => {
  return (
    <List mt="6">
      {quests.map((quest) => (
        <ListItem display="flex" key={quest.id}>
          <QuestCard {...quest} onClick={() => handleSelectQuest(quest)} />
        </ListItem>
      ))}
    </List>
  );
};
